<template>
<div class="dashboard__col calendar">
  <CalendarModalAdd 
    v-scroll-lock="modal2"
    :selectUser="selectUser"
    :class="{'active': modal2}"
    :confirmation-list="calendarList"
    @reload="reload"
    @close="modal2 = false" 
    @warning="checkModal = true"
  />
  <CheckModal class="checkm-modal" :class="{'active': checkModal}" @close="checkModal = false">
    <template v-slot:header>
      {{$t('warnings')}}
    </template>
    <template v-slot:body>
      <div class="inp__row">
        <strong>{{$t('dashboard.applications.warnings')}}</strong>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal-footer">
      
      </div>
    </template>

  </CheckModal>
  <!-- <h1>{{$t('dashboard.applications.title')}}</h1> -->
  <div class="requests__links">
    <div class="left">
      <a class="active" @click.prevent ="dashboardCalendar = false, showCalendarMethod()">{{$t('dashboard.applications.list')}}</a>
    </div>
    <div class="right" v-show="true">
      <button @click="newUser()" class="add-query">{{$t('dashboard.applications.btn.addUser')}} +</button>
    </div>
  </div>
  <div class="requests__table__wrp">
    <div class="top">
      <div class="count">{{ unconfiredList.length }} {{$t('dashboard.applications.title')}}</div>
    </div>
    <div class="requests__table">
      <div class="t__top">
        <div class="name">{{ $t('dashboard.applications.table.name') }}</div>
        <div class="phone__number">{{ $t('dashboard.applications.table.phone') }}</div>
        <div class="start__date">{{ $t('dashboard.applications.table.data') }}</div>
        <div class="end__date" :class="{'warning-alert' : warningColor}">{{ $t('dashboard.applications.table.endData') }}</div>
      </div>

      <div class="rq__card" v-for="(item, ind) in unconfiredList" :key="item.id">
        <div class="name">{{ item.client_name }}</div>
        <a :href="'tel:' + item.client_phone" class="phone__number">{{ item.client_phone }}</a>
        <div class="start__date">{{item.date_creation.substr(0, 10)}}</div>
        <div class="end__date">{{item.date_of_receipt ? item.date_of_receipt : '--'}}</div>
        <div class="btns">
          <div class="edit__btn" @click="deleteUser(ind, unconfiredList)">
            <svg class="icon-delete" width="16px" height="16px">
              <use xlink:href="/icons.svg#delete"></use>
            </svg>
          </div>
          <div class="edit__btn" @click="editUser(ind, unconfiredList)">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.33203 2.66666H2.66536C2.31174 2.66666 1.9726 2.80713 1.72256 3.05718C1.47251 3.30723 1.33203 3.64637 1.33203 3.99999V13.3333C1.33203 13.6869 1.47251 14.0261 1.72256 14.2761C1.9726 14.5262 2.31174 14.6667 2.66536 14.6667H11.9987C12.3523 14.6667 12.6915 14.5262 12.9415 14.2761C13.1916 14.0261 13.332 13.6869 13.332 13.3333V8.66666" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12.332 1.66665C12.5972 1.40144 12.957 1.25244 13.332 1.25244C13.7071 1.25244 14.0668 1.40144 14.332 1.66665C14.5972 1.93187 14.7462 2.29158 14.7462 2.66665C14.7462 3.04173 14.5972 3.40144 14.332 3.66665L7.9987 9.99999L5.33203 10.6667L5.9987 7.99999L12.332 1.66665Z" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="confirm__btn" @click="confirm(ind, $event)">{{ $t('dashboard.applications.btn.title') }}</div>
        </div>
      </div>
      <div v-if="!unconfiredList.length" class="empty-message">
        <h4>{{$t('dashboard.applications.emptyMessage')}}</h4>
        <svg class="icon-delete" width="50px" height="50px">
          <use xlink:href="/icons.svg#empty-message"></use>
        </svg>
      </div>

      <div class="confirmed__col" :class="{ 'open': showConfirmed }">
        <div class="confirm__label" @click="showConfirmed = !showConfirmed">
          <span>{{ $t('dashboard.applications.table.title') }}</span>
          <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 3L7 9L13 3" stroke="#6B6B78"/>
          </svg>
        </div>

        <div class="rq__card" v-for="(item, ind) in confirmationList" :key="item.id">
          <div class="name">{{ item.client_name }}</div>
          <a :href="'tel:' + item.client_phone" class="phone__number">{{ item.client_phone }}</a>
          <div class="start__date">{{item.date_creation.substr(0, 10)}}</div>
          <div class="end__date">{{item.date_of_receipt ? item.date_of_receipt : '--'}}</div>
          <div class="btns">
            <div class="edit__btn" @click="deleteUser(ind, confirmationList)">
              <svg class="icon-delete" width="16px" height="16px">
                <use xlink:href="/icons.svg#delete"></use>
              </svg>
            </div>
            <div class="edit__btn" @click="editUserCalendar(confirmationList[ind])">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.33203 2.66666H2.66536C2.31174 2.66666 1.9726 2.80713 1.72256 3.05718C1.47251 3.30723 1.33203 3.64637 1.33203 3.99999V13.3333C1.33203 13.6869 1.47251 14.0261 1.72256 14.2761C1.9726 14.5262 2.31174 14.6667 2.66536 14.6667H11.9987C12.3523 14.6667 12.6915 14.5262 12.9415 14.2761C13.1916 14.0261 13.332 13.6869 13.332 13.3333V8.66666" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.332 1.66665C12.5972 1.40144 12.957 1.25244 13.332 1.25244C13.7071 1.25244 14.0668 1.40144 14.332 1.66665C14.5972 1.93187 14.7462 2.29158 14.7462 2.66665C14.7462 3.04173 14.5972 3.40144 14.332 3.66665L7.9987 9.99999L5.33203 10.6667L5.9987 7.99999L12.332 1.66665Z" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="confirmed" @click="cancellation(ind)">{{ $t('dashboard.applications.btn.done') }}</div>
          </div>
        </div>
        <div v-if="!confirmationList.length" class="empty-message">
          <h4>{{$t('dashboard.applications.msgConfirmate')}}</h4>
          <svg class="icon-delete" width="50px" height="50px">
            <use xlink:href="/icons.svg#message-empty"></use>
          </svg>
        </div>

      </div>
    </div>
  </div>
  <div class="my-calendar" >
    <div class="months">
      <div class="wrapp-months">
        <VueSlickCarousel 
        @beforeChange="selectMounth"	
          ref="sliderMonths" 
          :swipe="false"
          :arrows="true"
          :dots="false"
          :slidesToShow="3"
          :centerMode="true"
          :focusOnSelect="true"
          :centerPadding="'15px'"
        >
        <div v-for="(m, ind) in mounths" :key="ind">
          <div class="mounth" @click="goBackToCalendar()">
            {{ m[langStorage] }}
          </div>
        </div>
        
        </VueSlickCarousel>
        
      </div>
    </div>
    <div class="calendar" v-if="!dashboardCalendar">
      <div class="row weeks">
        <div class="col" v-for="(item, ind) in $t('requests.daysOfWeek')" :key="ind">{{item}}</div>
      </div>
      <div class="row" v-for="(row, inx) in calendarDays" :key="inx">
        <div class="col"
          v-for="(col, ind) in row"
          :class="[today == col && selectedMounthNumber == mounthNumber ? 'today' : '', calendarDay(col).length ? 'active' : '']"
          :key="ind"
          @click="selectDate"
        > <!--class busy-->
          <div class="day">{{ col }}</div>
          <div class="request-list">
            <ul class="list">
              <li v-for="rec in calendarDay(col)" :key="rec.id" :class="{ 'red': false }">
                <div class="point"></div>
                <span class="day_id" style="display:none">{{rec.id}}</span>
                {{rec.time_of_receipt.slice(0,5)}}
              </li>  
            </ul>
          </div>

        </div>
      </div>

    </div>
  </div>

  <div v-show="dashboardCalendar" class="dashboard__col day__col">
    <!-- <div class="months">
      <a class="prev" @click="$refs.sliderMonths.prev()">
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9 1L3 7L9 13" stroke="#6B6B78"/>
        </svg>
      </a>
      <a class="month">
        {{ mounths[selectedMounthNumber][langStorage] }}
      </a>
      <a class="next" @click="$refs.sliderMonths.next()">
        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 1L9 7L3 13" stroke="#6B6B78"/>
        </svg>
      </a>
    </div> -->
    <div class="days" style="width:100%">
      <VueSlickCarousel 
        @beforeChange="selectDay"	
          class="sliderDays"
          ref="sliderDays" 
          :swipe="false"
          :arrows="false"
          :dots="false"
          :slidesToShow="17"
          :centerMode="true"
          :focusOnSelect="true"
          :centerPadding="'12px'"
          :responsive="responsiveOptionsDays"
        >
        <div v-for="(item, ind) in dayOfWeek" :key="ind + item.en">
          <div class="day" :class="{'past': item.number < today && selectedMounthNumber == mounthNumber}" @click="selectDateClickEvent = true">
            <a class="num">{{item.number}}</a>
            <div class="name">{{item[langStorage]}}</div>
          </div>
        </div>       
      </VueSlickCarousel>
    </div>
    <div class="table">
      <!-- <div class="hour__row">
        <div class="hour">
          08:00
        </div>
        <div class="line">
          <div class="add" @click="modal2 = true">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.375 8.625V15.5H8.625V8.625H15.5V7.375H8.625V0.5H7.375V7.375H0.5V8.625H7.375Z" fill="white"/>
            </svg>
          </div>
        </div>
      </div> -->
      <!-- <div class="hour__row">       
        <div class="hour">
          09:00
        </div>
        <div class="card finished">
          <div class="left">
            <div class="name">
              Bessie Cooper
            </div>
            <a href="tel:(229) 555-0109" class="phone">
              (229) 555-0109
            </a>
            <div class="duration">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 0C2.69168 0 0 2.69154 0 6C0 9.30846 2.69168 12 6 12C9.30846 12 12 9.30846 12 6C12 2.69154 9.30832 0 6 0ZM6 11.1241C3.17461 11.1241 0.875906 8.82539 0.875906 6C0.875906 3.17461 3.17461 0.875906 6 0.875906C8.82539 0.875906 11.1241 3.17459 11.1241 5.99986C11.1241 8.82539 8.82539 11.1241 6 11.1241Z" fill="#6B6B78"/>
                <path d="M8.04324 6.00002H6.14544V3.37228C6.14544 3.13038 5.94938 2.93433 5.70748 2.93433C5.46559 2.93433 5.26953 3.13038 5.26953 3.37228V6.43798C5.26953 6.67987 5.46559 6.87593 5.70748 6.87593H8.04324C8.28514 6.87593 8.48119 6.67987 8.48119 6.43798C8.48119 6.19608 8.28514 6.00002 8.04324 6.00002Z" fill="#6B6B78"/>
              </svg>
              <span>30:00 min.</span>
            </div>
          </div>
          <strong class="status">
            Consultație terminată
          </strong>
        </div>
      </div> -->
      <div class="hour__row" v-for="(item, ind) in calendarDayList" :key="item.id">
        <div class="hour">
         {{item.time_of_receipt.slice(0,5)}}
        </div>
        <div class="card">
          <div class="left">
            <div class="name">
              {{item.client_name}}
            </div>
            <a :href="`tel:${item.client_phone}`" class="phone">
              {{item.client_phone}}
            </a>
            <div class="duration" v-if="false">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 0C2.69168 0 0 2.69154 0 6C0 9.30846 2.69168 12 6 12C9.30846 12 12 9.30846 12 6C12 2.69154 9.30832 0 6 0ZM6 11.1241C3.17461 11.1241 0.875906 8.82539 0.875906 6C0.875906 3.17461 3.17461 0.875906 6 0.875906C8.82539 0.875906 11.1241 3.17459 11.1241 5.99986C11.1241 8.82539 8.82539 11.1241 6 11.1241Z" fill="#6B6B78"/>
                <path d="M8.04324 6.00002H6.14544V3.37228C6.14544 3.13038 5.94938 2.93433 5.70748 2.93433C5.46559 2.93433 5.26953 3.13038 5.26953 3.37228V6.43798C5.26953 6.67987 5.46559 6.87593 5.70748 6.87593H8.04324C8.28514 6.87593 8.48119 6.67987 8.48119 6.43798C8.48119 6.19608 8.28514 6.00002 8.04324 6.00002Z" fill="#6B6B78"/>
              </svg>
              <span>30:00 min.</span>
            </div>
          </div>
          <div class="right">
            <strong class="status">
              {{$t('requests.busy')}}
            </strong>
            <div class="edit" @click="editUserCalendar(item)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.33203 2.66669H2.66536C2.31174 2.66669 1.9726 2.80716 1.72256 3.05721C1.47251 3.30726 1.33203 3.6464 1.33203 4.00002V13.3334C1.33203 13.687 1.47251 14.0261 1.72256 14.2762C1.9726 14.5262 2.31174 14.6667 2.66536 14.6667H11.9987C12.3523 14.6667 12.6915 14.5262 12.9415 14.2762C13.1916 14.0261 13.332 13.687 13.332 13.3334V8.66669" stroke="#0066FF" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12.332 1.66665C12.5972 1.40144 12.957 1.25244 13.332 1.25244C13.7071 1.25244 14.0668 1.40144 14.332 1.66665C14.5972 1.93187 14.7462 2.29158 14.7462 2.66665C14.7462 3.04173 14.5972 3.40144 14.332 3.66665L7.9987 9.99999L5.33203 10.6667L5.9987 7.99999L12.332 1.66665Z" stroke="#0066FF" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div class="trash" @click="deleteUserCalendar(item.id , ind)">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5 3.5L4.125 13.5C4.15469 14.0778 4.575 14.5 5.125 14.5H10.875C11.4272 14.5 11.8397 14.0778 11.875 13.5L12.5 3.5" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2.5 3.5H13.5H2.5Z" fill="#515076"/>
                <path d="M2.5 3.5H13.5" stroke="#515076" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M6 3.5V2.25C5.99971 2.15143 6.01892 2.05377 6.05651 1.96265C6.09409 1.87152 6.14933 1.78873 6.21903 1.71903C6.28873 1.64933 6.37152 1.59409 6.46265 1.55651C6.55377 1.51892 6.65143 1.49971 6.75 1.5H9.25C9.34858 1.49971 9.44623 1.51892 9.53736 1.55651C9.62848 1.59409 9.71128 1.64933 9.78098 1.71903C9.85068 1.78873 9.90591 1.87152 9.9435 1.96265C9.98109 2.05377 10.0003 2.15143 10 2.25V3.5" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 5.5V12.5" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.75 5.5L6 12.5" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M10.25 5.5L10 12.5" stroke="#515076" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="hour__row" v-for="item in 8" :key="item">
        <div class="hour">
          {{10 + item}}:00
        </div>
        <div class="line">
          <div class="add" @click="modal2 = true">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.375 8.625V15.5H8.625V8.625H15.5V7.375H8.625V0.5H7.375V7.375H0.5V8.625H7.375Z" fill="white"/>
            </svg>
          </div>
        </div>
      </div> -->
     
    </div>
  </div>

</div>
</template>

<script>
import LangRu from '@/lang/dashboard/applications/ru' 
import LangRo from '@/lang/dashboard/applications/ro' 
import LangEng from '@/lang/dashboard/applications/eng'
import LangEs from '@/lang/dashboard/applications/es'

import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'



import CalendarModalAdd from '@/components/CalendarModalAdd'
import CheckModal from '@/components/CheckModal'
import { mapGetters } from 'vuex';

const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins: [LangRu, LangRo, LangEng, LangEs],
  components: {
    CalendarModalAdd,
    VueSlickCarousel,
    CheckModal
  },
  data(){
    return {
      showConfirmed: true,
      requests: [
        {
          name: 'Bessie Cooper',
          phone: '(229) 555-0109',
          startDate: '21 Aug, 12:30',
          endDate: '20 Aug, 09:14',
          status: ''
        },
        {
          name: 'Floyd Miles',
          phone: '(229) 555-0109',
          startDate: '21 Aug, 12:30',
          endDate: '20 Aug, 09:14',
          status: ''
        },
        {
          name: 'Annette Black',
          phone: '(229) 555-0109',
          startDate: '21 Aug, 12:30',
          endDate: '20 Aug, 09:14',
          status: ''
        },
        {
          name: 'Arlene McCoy',
          phone: '(229) 555-0109',
          startDate: '21 Aug, 12:30',
          endDate: '20 Aug, 09:14',
          status: ''
        },
        {
          name: 'Guy Hawkins',
          phone: '(229) 555-0109',
          startDate: '21 Aug, 12:30',
          endDate: '20 Aug, 09:14',
          status: ''
        },
        {
          name: 'Jerome Bell',
          phone: '(229) 555-0109',
          startDate: '21 Aug, 12:30',
          endDate: '20 Aug, 09:14',
          status: 'confirmed'
        },
        {
          name: 'Cody Fisher',
          phone: '(229) 555-0109',
          startDate: '21 Aug, 12:30',
          endDate: '20 Aug, 09:14',
          status: 'confirmed'
        },
        {
          name: 'Jacob Jones',
          phone: '(480) 555-0103',
          startDate: '21 Aug, 12:30',
          endDate: '20 Aug, 09:14',
          status: 'confirmed'
        },
      ],
      confirmationList: [],
      unconfiredList: [],
      dashboardCalendar: false, 
      calendarDays: [],
      today: '',
      mounthNumber: '',
      year: '',
      selectedMounthNumber: '',
      selectedDayNumber: '',
      modal2: false,
      selectUser: {
        id: '',
        name: '',
        phone: '',
        date: '',
        time: 'HH:mm',
        duration: '00:30',
        comment: '',
        confirmation: false,
      },
      calendarList: [],
      calendarDayList: [],
      responsiveOptionsDays:[
        {
          breakpoint: 430,
          settings: {
            slidesToShow: 5
          }
        },
      ],
      warningColor: false,
      selectDateClickEvent: false,
      checkModal: false
    }
  },
  async beforeMount(){
    let date = new Date();
    this.today = date.getDate();
    this.mounthNumber = date.getMonth();
    this.selectedMounthNumber = this.mounthNumber;
    this.year = date.getFullYear(); 
    this.calendarDays = this.createCalendar(this.year, this.mounthNumber + 1);
    await this.getList();
    this.calendarList = await this.getCalendarList();
    try{
      this.$refs.sliderMonths.goTo(this.mounthNumber);
    }
    catch(er){
      console.warn("goTo");
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang',
      user: 'user/user'
    }),
    noConfirmed(){
      return this.requests.filter(req => req.status != 'confirmed');
    },
    confirmed(){
      return this.requests.filter(req => req.status == 'confirmed');
    },
    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      else if(this.langStorage == 'es'){
        return this.langEs;
      }
      return this.langEng;
    },
    mounths(){
      return [
        {
          ro: 'Ianuarie',
          ru: 'Январь',
          en: 'January',
          es: 'Enero'
        },
        {
          ro: 'Februarie',
          ru: 'Февраль',
          en: 'February',
          es: 'Febrero'
        },
        {
          ro: 'Martie',
          ru: 'Март',
          en: 'March',
          es: 'Marzo'
        },
        {
          ro: 'Aprilie',
          ru: 'Апрель',
          en: 'April',
          es: 'Abril'
        },
        {
          ro: 'Mai',
          ru: 'Май',
          en: 'May',
          es: 'Mayo'
        },
        {
          ro: 'Iunie',
          ru: 'Июнь',
          en: 'June',
          es: 'Junio'
        },
        {
          ro: 'Iulie',
          ru: 'Июль',
          en: 'July',
          es: 'Julio'
        },
        {
          ro: 'August',
          ru: 'Август',
          en: 'August',
          es: 'Agosto'
        },
        {
          ro: 'Septembrie',
          ru: 'Сентябрь',
          en: 'September',
          es: 'Septiembre'
        },
        {
          ro: 'Octombrie',
          ru: 'Октябрь',
          en: 'October',
          es: 'Octubre'
        },
        {
          ro: 'Noiembrie',
          ru: 'Ноябрь',
          en: 'November',
          es: 'Noviembre'
        },
        {
          ro: 'Decembrie',
          ru: 'Декабрь',
          en: 'December',
          es: 'Diciembre'
        },
      ]
    },
    dayOfWeek(){
      let en = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];
      let ru = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];
      let ro = ['Lu', 'Ma', 'Mi', 'Jo', 'Vi', 'Sb', 'Du'];
      let es = ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá', 'Do'];
      let mas = [];
      this.calendarDays.forEach(week => {
        week.forEach((item, ind) => {   
          if(item != ' '){
            mas.push({
              'ro': ro[ind],
              'ru': ru[ind],
              'en': en[ind],
              'es': es[ind],
              number: item
            });
          }
        });
      });

      return mas;
    }
  },
  methods: {
    async selectDate(e){
      let day = e.target.closest('.col').children[0].innerText;
      this.selectedDayNumber = day;
      this.$refs.sliderDays.goTo(day - 1);
      this.dashboardCalendar = true; 
      this.selectDateClickEvent = true;

      await this.getCalendarDayList();
    },
    async selectMounth(old, ind){
      this.selectedMounthNumber = ind;
      this.calendarDays = this.createCalendar(this.year, ind + 1);
      this.calendarList = await this.getCalendarList();
    },
    async selectDay(old, ind){
      if(this.selectDateClickEvent){
        try {
          let y = this.year;
          let m = this.selectedMounthNumber + 1 < 10 ? '0' + (this.selectedMounthNumber + 1) : this.selectedMounthNumber + 1;
          let d = ind + 1 < 10 ? '0' + (ind + 1) : (ind + 1);
          const request = await fetch(`${domain}/cabinet/appointment/?date_of_receipt=${y}-${m}-${d}&confirmation=true`, {
            headers: {
              Authorization: `Token ${this.user.token}`
            }
          });
          if(request.ok){
            this.calendarDayList = await request.json();
          }
        }
        catch(er){
          console.error(er);
        }
      }
      this.selectDateClickEvent = false;
    },
    createCalendar(year, month){
      let numbers = [];
      let mon = month - 1; // месяцы в JS идут от 0 до 11, а не от 1 до 12
      let d = new Date(year, mon);
       
      // пробелы для первого ряда
      // с понедельника до первого дня месяца
      // * * * 1  2  3  4
      for (let i = 0; i < getDay(d); i++) {        
        numbers.push(' ');
      }
    
      // <td> ячейки календаря с датами
      while (d.getMonth() == mon) {       
        numbers.push(d.getDate());
    
        if (getDay(d) % 7 == 6) { // вс, последний день - перевод строки         
          numbers.push('');
        }
    
        d.setDate(d.getDate() + 1);
      }
    
      // добить таблицу пустыми ячейками, если нужно
      // 29 30 31 * * * *
      if (getDay(d) != 0) {
        for (let i = getDay(d); i < 7; i++) {
          //table += '<td></td>';
          numbers.push('');
        }
      }
     
      let arr = [];
      let mas = [];
      for(let i = 0; i < numbers.length; i++){
        if(numbers[i] != ''){
          mas.push(numbers[i]);
        }
        else {
          arr.push(mas);
          mas = [];
        }
      }

      return arr;

      function getDay(date) { // получить номер дня недели, от 0 (пн) до 6 (вс)
        let day = date.getDay();
        if (day == 0) day = 7; // сделать воскресенье (0) последним днем
        return day - 1;
      }
    },
    showCalendarMethod(){
      this.dashboardCalendar = false;
      this.$refs.sliderMonths.goTo(this.mounthNumber);
    },
    async getList(){
      try {
        const request = await fetch(`${domain}/cabinet/not-confirm-appointment/`, {
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        });
        this.unconfiredList = await request.json();
           
        const request2 = await fetch(`${domain}/cabinet/appointment/?date_of_receipt=${this.myDate()}&confirmation=true`, {
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        });
        let today = await request2.json();

        const request3 = await fetch(`${domain}/cabinet/appointment/?date_of_receipt=${this.myDate(1)}&confirmation=true`, {
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        });
        let tomorrow = await request3.json();

        this.confirmationList = today.concat(tomorrow);
      }
      catch(err){
        console.error(err);
      }
    },
    myDate(day){
      let d = new Date();
      if(day){
        d.setDate(d.getDate() + day);
      }
      return `${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1): (d.getMonth() + 1) }-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}`;
    },
    editUser(ind, list){
      let user = list[ind];
      this.selectUser.id = user.id;
      this.selectUser.name = user.client_name;
      this.selectUser.phone = user.client_phone;
      this.selectUser.date = user.date_of_receipt;
      this.selectUser.time = user.time_of_receipt != null ? user.time_of_receipt : 'HH:mm';
      this.selectUser.comment = user.client_comments;
      this.selectUser.confirmation = false;
      this.modal2 = true;
    },
    async deleteUser(ind, list){
      let user = list[ind];
      try {
        list.splice(ind, 1);
        await fetch(`${domain}/cabinet/appointment/${user.id}/`, {
          method: "DELETE",
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        })
        await this.reload();
      }
      catch(er){
        console.error(er);
      }
    },
    editUserCalendar(obj){
      let user = obj;
      this.selectUser.id = user.id;
      this.selectUser.name = user.client_name;
      this.selectUser.phone = user.client_phone;
      this.selectUser.date = user.date_of_receipt;
      this.selectUser.time = user.time_of_receipt != null ? user.time_of_receipt : 'HH:mm';
      this.selectUser.comment = user.client_comments;
      this.selectUser.confirmation = true;
      this.modal2 = true;
    },
    newUser(){
      this.selectUser.id = '';
      this.selectUser.name = '';
      this.selectUser.phone = '';
      this.selectUser.date = '';
      this.selectUser.time = 'HH:mm';
      this.selectUser.comment = '';
      this.selectUser.duration = '00:30';
      this.modal2 = true;
    },
    confirm(ind, event) {
      const item = this.unconfiredList[ind];
      if (this.checkingForRepetitions(item)) {
        this.checkModal = true;
        return false
      }
      this.change(item, true);
      this.reload();
      if(item.date_of_receipt == null) {
        this.warningColor = true;
        event.target.style.color = "yellow"
        setTimeout(() => {
          this.warningColor = false;
          event.target.style.color = "#ffffff"
        }, 2000);         
      }
    },
    cancellation(ind){
     this.change(this.confirmationList[ind], false);
     this.reload();
    },
    async change(item, status){
      try {  
        let user = item;
        if(user.date_of_receipt && user.time_of_receipt != "00:00:00") {
          user.confirmation = status;
          await fetch(`${domain}/cabinet/appointment/${user.id}/`, {
            method: "PUT",
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Token ${this.user.token}`
            },
            body: JSON.stringify(user)
          });
          await this.getList();    
        }
      }
      catch(err){
        console.error(err);
      }
    },
    async getCalendarList(){
      let m = this.selectedMounthNumber + 1 < 10 ? '0' + (this.selectedMounthNumber + 1) : this.selectedMounthNumber + 1;
      let lastDayOfMonth = new Date(this.year, this.selectedMounthNumber + 1, 0).getDate();
      try {
        const request = await fetch(`${domain}/cabinet/calendar/?date_of_receipt_start=${this.year}-${m}-01&date_of_receipt_end=${this.year}-${m}-${lastDayOfMonth}`, {
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        });    
        let list = await request.json();
        return list.filter(e => e.confirmation);           
      }
      catch(err){
        console.error(err);
      }
    },
    calendarDay(day){
      let d = day < 10 ? '0' + day : day;
      return this.calendarList.filter(item => item.date_of_receipt.slice(-2) == d);
    },
    async getCalendarDayList(){
      try {
        let y = this.year;
        let m = this.selectedMounthNumber + 1 < 10 ? '0' + (this.selectedMounthNumber + 1) : this.selectedMounthNumber + 1;
        let d = this.selectedDayNumber < 10 ? '0' + this.selectedDayNumber : this.selectedDayNumber;
        const request = await fetch(`${domain}/cabinet/appointment/?date_of_receipt=${y}-${m}-${d}&confirmation=true`, {
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        });

        this.calendarDayList = await request.json();
      }
      catch(er){
        console.error(er);
      }
    },
    async reload(){
      await this.getList()
      if(this.selectedDayNumber) {
        await this.getCalendarDayList();
      }
     this.calendarList = await this.getCalendarList();
    },
    async deleteUserCalendar(id, ind){
      try {
        this.calendarDayList.splice(ind, 1);
        await fetch(`${domain}/cabinet/appointment/${id}/`, {
          method: "DELETE",
          headers: {
            Authorization: `Token ${this.user.token}`
          }
        })
        await this.reload();
      }
      catch(er){
        console.error(er);
      }
    },
    checkingForRepetitions(item) {
      let flag = false;
      if(this.calendarList) {
        flag = this.calendarList.some(e => {
          if(item.date_of_receipt == e.date_of_receipt) {
            const time = item.time_of_receipt.split(':');
            const time2 = e.time_of_receipt.split(':');
            return time[0] == time2[0];
          }
          return false;
        });
      }
      return flag;
    },
    goBackToCalendar() {
      this.dashboardCalendar = false;
    }
  }
}
</script>

<style lang="scss">
.night {
  .dashboard__col.calendar .my-calendar .months .wrapp-months .slick-current .mounth {
    color: white !important;
  }
}
.dashboard__col.calendar {
  .checkm-modal {
    .inp__row {
      display: flex;
      align-items: center;
      min-height: 100px;
    }
    .modal-footer {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      .cancel {
        background: #dd0000;
      }
    }
  }
  .requests__links {
    margin-top: 16px;
    .left {
      a {
        cursor: pointer;
      }
    }
  }
  .add-query {
    border: none;
    outline: none;
    color: white;
    background-color: #0066FF;
    border-radius: 6px;
    padding: 12px 24px;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background-color: #003cff98;
    }
  }
  .my-calendar {
    width: 100%;
    margin-top: 50px;
    .months {
      width: 100%;
      max-width: 380px;
      height: 24px;
      margin: auto;
      cursor: pointer;

      .wrapp-months {
        .slick-current {
          .mounth {
            color: black !important;
          }
        }
        .mounth {
          display: flex;
          justify-content: center;
          font-weight: 500;
          font-size: 16px;
          color: #ADADBD;
        }
        .slick-prev:before, .slick-next:before {
          color: black;
        }

      }

      .slick-prev {
        background: #000001;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        left: 0;
        top: 10px;

        &:hover {
          background: #51515e;
        }

        &::before {
          content: "";
          display: block;
          width: 9px;
          height: 3px;
          background: #f5f4fb;
          border-radius: 5px;
          transform: rotate(150deg);
          position: absolute;
          left: 4px;
          top: 5px;
          opacity: 1;
        }
        &::after {
          content: "";
          display: block;
          width: 9px;
          height: 3px;
          background: #f5f4fb;
          border-radius: 5px;
          transform: rotate(-150deg);
          position: absolute;
          left: 4px;
          top: 9px;
        }
      }

      .slick-next {
        background: #000001;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        right: 0;
        top: 10px;

        &:hover {
          background: #51515e;
        }

        &::before {
          content: "";
          display: block;
          width: 9px;
          height: 3px;
          background: #f5f4fb;
          border-radius: 5px;
          transform: rotate(28deg);
          position: absolute;
          left: 5px;
          top: 6px;
          opacity: 1;
        }
        &::after {
          content: "";
          display: block;
          width: 9px;
          height: 3px;
          background: #f5f4fb;
          border-radius: 5px;
          transform: rotate(-28deg);
          position: absolute;
          left: 5px;
          top: 10px;
        }
      }
    }

    .calendar {
      margin-top: 16px;
      background-color: white;

      .row.weeks {
        border-bottom: 1px solid #EFEEFE;
        .col {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #6B6B78;
          font-weight: bold;
          font-size: 12px;
          height: 64px;
          padding: 0;
          text-transform: uppercase;
          border: none;
          &:hover {
           background-color:  white;
          }
        } 
      }
      
      .row {
        display: flex;
        .col {
          width: 100%;
          max-width: 151px;
          height: 150px;
          color: #ADADBD;
          font-weight: bold;
          font-size: 24px;
          padding-left: 24px;
          padding-top: 16px;

         
          border-bottom: 1px solid #EFEEFE;
          border-right: 1px solid #EFEEFE;
          transition: 0.3s;
          cursor: pointer;
          // &:last-child {
          //   border-right: none;
          // }
          &:hover {
            background-color:  #008cff2f;
          }
          &.today {
            background-color: #0066FF;
            color: #ffffff;
          }
          &.busy {
            background-color: #FFEBF6;
            color: black;
          }
          &.active {
            color: #000001;
          }
          &.red {                      
            background-color: #FFEBF6;            
          }


          .request-list {
            height: 100px;
            overflow: auto;
            scrollbar-color: #F5F4FB transparent;     /* «цвет ползунка» «цвет полосы скроллбара» */
            scrollbar-width: thin;  /* толщина */

            &::-webkit-scrollbar { /* полоса прокрутки (скроллбар) */
              width: 5px;            
              background-color: transparent;
            }
            &::-webkit-scrollbar-thumb { /* ползунок скроллбара */
              background-color: #F5F4FB; 
              border-radius: 2em;
              box-shadow: inset 1px 1px 10px #E6DEDE;
            }
            .list {
             
              padding: 0;
              margin: 0;
              font-weight: normal;
              font-size: 14px;
              list-style: none;
              li {
                display: flex;
                align-items: center;
                width: 100%;
                max-width: 100px;
                height: 20px;
                color: white;
                background-color: #14685D;
                border-radius: 6px;
                margin-top: 5px;
                &.red {
                  background-color: #EB236B;
                }

                .point {
                  width: 6px;
                  height: 6px;
                  background-color: white;
                  border-radius: 50%;
                  margin: 0 7px;
                }
              }
            }
          }

        }
      }

    }
  }
  .dashboard__col.day__col {
    margin-top: 50px;
    .day {
      cursor: pointer;
    }
  }
  .sliderDays {
    width: 100%;
    
    .slick-current {
      .day {
        border-bottom: 2px solid #0066FF;
        .num {
          background: #0066FF !important;
          color: #FFF !important;
        }
        .name {
          color: #000001 !important;
        }
      }
    }
  }
  .requests__table {
    .rq__card {
      .confirm__btn {
        @media(max-width: 430px) {
          background-color: #F5F4FB;
          background-image: url("/img/tap.png") !important;
          background-size: 80%;
        }
      }
    }
    .confirmed__col {
      .rq__card {
        .confirmed {
          cursor: pointer;
          transition: 0.2s;
          &:hover {
            background-color: #eae9f2;
          }
          @media(max-width: 430px) {
            background-color: #187bf7;
            background-image: url("/img/back.png") !important;
            background-size: 50%;
          }
        }
      }
    }
  }
  .day__col .table {
    min-height: 400px;
  }
  .edit__btn {
    transition: 0.5s;
    .icon-delete {
      transition: 0.5s;
    }
    &:hover {
      background: #e0dfe6 !important;
      .icon-delete {
        fill: red;
      }
    }


  }
  .empty-message {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    
    color: #6B6B78;
    
  }
  .warning-alert {
    font-weight: 900;
    color: red;
  }

  @media(max-width: 430px){
    .my-calendar {
      .slick-arrow {
        // display: none !important;
      }
      .calendar .row .col {
        font-size: 14px;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 54px; 
        overflow: hidden;
        & .request-list .list {
          font-weight: 900;
          list-style: none; 
          li {
            border-radius: 4px;
            padding: 4px;
            .point {
              display: none;
            }
          }
        }     
      }
    }
     
     .right {
      width: 100%;
      padding: 24px 0px;
      .add-query {
        width: 100%;
        padding: 15px 24px;
      }
    }
  }
  @media(max-width: 375px) {
    .my-calendar {
      .calendar .row .col {
        max-width: 49px; 
      }
    }
  }
  @media(max-width: 320px) {
        .my-calendar {
      .calendar .row .col {
        max-width: 41px; 
      }
    }
  }
}
</style>